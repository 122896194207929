import http from "./axios/http";

export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/nxzone/get_lobby_url?t=' + new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}

export function getGameList(vendor) {
  return http.get('/casino/nxzone/get_game_list?t=' + new Date().getTime(), {'vendor':vendor})
}

export function getGameLancher(gameId,vendor,gtype) {
  return http.get('/casino/nxzone/get_game_lancher?t=' + new Date().getTime(), {'gameId':gameId,'vendor':vendor,'gtype':gtype})
}


export function getAccountBalance() {
  return http.get('/casino/nxzone/get_account_balance?t=' + new Date().getTime(), {})
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/nxzone/add_member_casino_cash?t=' + new Date().getTime(), {'amount':amount})
}

export function subjectMemberCasinoCash() {
  return http.get('/casino/nxzone/subject_member_casino_cash?t=' + new Date().getTime())
}

export function getCasinoMaintaining() {
  return http.get('/casino/nxzone/get_casino_maintaining?t=' + new Date().getTime())
}


export default {


    LEISURE_COMP_DONGHANG: 1,
    LEISURE_COMP_LOTUS: 2,
    LEISURE_COMP_CROWN: 3,
    LEISURE_COMP_COMSOON: 4,
    LEISURE_COMP_SKY: 5,
    LEISURE_COMP_BET365: 6,
    LEISURE_COMP_STAR_SADALI: 8,
    LEISURE_COMP_MGM: 9,
    LEISURE_COMP_NAMED: 10,
    LEISURE_COMP_TOKENGAME: 11,
    LEISURE_COMP_EOS: 12,
    LEISURE_COMP_HS: 13,
    LEISURE_COMP_SPEEDGAME: 14,
    LEISURE_COMP_JWCASINO: 15,
    LEISURE_COMP_GAMEMOA: 16,
    LEISURE_COMP_TGAMEPOWERBALL: 17,
    LEISURE_COMP_NEXT: 18,
    LEISURE_COMP_SUREMAN: 19,
    LEISURE_COMP_PAMGAME: 20,

    LEISURE_KIND_POWERBALL: 1,
    LEISURE_KIND_POWERSADALI: 2,
    LEISURE_KIND_SPEEDKINO: 3,
    LEISURE_KIND_KINOSADALI: 4,
    LEISURE_KIND_NAMED_DALIDALI: 10,
    LEISURE_KIND_NAMED_SADALI: 11,
    LEISURE_KIND_NAMED_SPEEDSADALI: 12,
    LEISURE_KIND_NAMED_SNAIL: 13,


    LEISURE_KIND_EOS_POWERBALL_1M: 32,
    LEISURE_KIND_EOS_POWERBALL_3M: 33,
    LEISURE_KIND_EOS_POWERBALL_5M: 34,
    LEISURE_KIND_HS_POWERBALL_3M: 35,
    LEISURE_KIND_HS_POWERBALL_5M: 36,

    LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK: 40,
    LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK: 41,
    LEISURE_KIND_BET365_HORCERACE: 50, //트렌담 파크
    LEISURE_KIND_BET365_HORCERACE_2: 51,
    LEISURE_KIND_BET365_HORCERACE_3: 52,
    LEISURE_KIND_BET365_HORCERACE_4: 53,
    LEISURE_KIND_BET365_SOCCER_PREMIERSHIP: 45,
    LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE: 46,
    LEISURE_KIND_BET365_SOCCER_EUROCUP: 47,
    LEISURE_KIND_BET365_SOCCER_WORLDCUP: 48,
    LEISURE_KIND_BET365_BASKETBALL_WARTER: 43,
    LEISURE_KIND_BET365_BASKETBALL_BAKER: 44,

    LEISURE_KIND_PAMGAME_MARIO: 54,
    LEISURE_KIND_PAMGAME_SLOT: 55,
    LEISURE_KIND_PAMGAME_DRAGONTIGER: 56,

    LEISURE_KIND_BAKARA_LOTUS_1: 25,
    LEISURE_KIND_ODDEVEN_LOTUS: 28,
    LEISURE_KIND_LOTUS_DRAGONTIGER: 30,
    LEISURE_KIND_SUTDA: 65,
    LEISURE_KIND_NINEBALL: 66,

    LEISURE_KIND_NEXT_RING1M: 68, //넥스트 1분링
    LEISURE_KIND_NEXT_POWERBALL: 69, //넥스트 파워볼

    LEISURE_KIND_SKY_SPEED_BAKARA: 70,
    LEISURE_KIND_SKY_ODDEVEN: 71,
    LEISURE_KIND_SKY_DICE: 72, //주사위

    LEISURE_KIND_MGM_ODDEVEN: 27,//홀짝
    LEISURE_KIND_MGM_BAKARA: 29,//바카라
    LEISURE_KIND_MGM_GOSTOP: 24,//바카라

    LEISURE_KIND_BOSCORE_POWERBALL: 75,//보스코어 파워볼
    LEISURE_KIND_STAR_SD_1: 76,
    LEISURE_KIND_STAR_SD_2: 77,
    LEISURE_KIND_STAR_SD_3: 78,

    LEISURE_KIND_SPEEDGAME_PDALI: 79, //황금돼지사다리
    LEISURE_KIND_SPEEDGAME_LM: 80, //라이언무지
    LEISURE_KIND_SPEEDGAME_SPLIT: 81,//스피릿


    LEISURE_KIND_JWCASINO_ANGELDEMONS: 82, //천사 악마
    LEISURE_KIND_JWCASINO_OSTRICHRUN: 83, //타조게임
    LEISURE_KIND_JWCASINO_SUPERMARIO: 84, //슈퍼마리오


    LEISURE_KIND_GAMEMOA_STAR_SADALI_1M: 85, //GameMoa 별다리1분
    LEISURE_KIND_GAMEMOA_STAR_SADALI_5M: 86, //GameMoa 별다리5분
    LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M: 87, //GameMoa 버블사다리1분
    LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M: 88, //GameMoa 별다리3분
    LEISURE_KIND_GAMEMOA_ACE: 89, //GameMoa 에이스 홀짝/오버언더


    LEISURE_KIND_TGAME365_CASINO: 90, //Tgame 카지노
    LEISURE_KIND_TGAME365_SLOT: 91, //Tgame 슬롯
    LEISURE_KIND_TGAME365_FISH: 92, //Tgame 슬롯
    LEISURE_KIND_TGAME365_FW: 93, //Tgame 토큰휠
    LEISURE_KIND_TGAME365_HILOW: 94, //Tgame 하이로
    LEISURE_KIND_TGAME365_SNAIL3: 95, //Tgame 달팽이3
    LEISURE_KIND_TGAME365_SNAIL4: 96, //Tgame 달팽이4
    LEISURE_KIND_TGAME365_RUOLLET: 97, //Tgame 룰렛
    LEISURE_KIND_TGAME365_HILOW_5S: 98, //Tgame 하이로
    LEISURE_KIND_TGAME365_BACCARAT: 99, //Tgame 하이로


    LEISURE_KIND_NAMED_NEWPOWERBALL_3S: 100, //네임드 뉴 파워볼 3분
    LEISURE_KIND_NAMED_NEWPOWERBALL_5S: 101, //네임드 뉴 파워볼 5분
    LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S: 102, //네임드 뉴 파워사다리 3분
    LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S: 103, //네임드 뉴 파워사다리 5분
    LEISURE_KIND_NAMED_DONGAHNG_SPEEDKINO: 104, //동행스피드키노

    LEISURE_KIND_NEXT_SADALI: 105, //넥스트 사다리
    LEISURE_KIND_NEXT_BACCARA: 106, //넥스트 바카라


    LEISURE_KIND_SUREMAN_POWERBALL_1M: 107,//슈어맨 파워볼 1분
    LEISURE_KIND_SUREMAN_POWERBALL_2M: 108,
    LEISURE_KIND_SUREMAN_POWERBALL_3M: 109,
    LEISURE_KIND_SUREMAN_SADALI_1M: 110,//슈어맨 사다리 1분
    LEISURE_KIND_SUREMAN_SADALI_2M: 111,
    LEISURE_KIND_SUREMAN_SADALI_3M: 112,

    //레이저 게임 속성
    //파워볼
    LEISURE_ATTRIBUTE_LOTTO_POWERBALL: 0,
    P_POWERBALL_NUMBER: 1,
    P_POWERBALL_INTERVAL: 2,
    P_POWERBALL_OVERUNDER: 3,
    P_POWERBALL_ODD_EVEN: 4,
    P_POWERBALL_ODDEVEN_UNDEROVER: 5,
    //일반볼 합계
    P_NORMALBALL_INTERVAL: 6,
    P_NORMALBALL_OVERUNDER: 7,
    P_NORMALBALL_ODD_EVEN: 8,
    P_NORMALBALL_BMS: 9,
    P_NORMALBALL_ODDEVEN_UNDEROVER: 10,
    P_NORMALBALL_ODDEVEN_BMS: 11,

    //파워사다리 홀짝 , 좌우측, 3줄4줄
    LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN: 20,
    LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT: 21,
    LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT: 22,
    LEISURE_ATTRIBUTE_POWERSADALI_CROSS: 23,

    //스피드키노 홀짝 ,오버언더
    LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN: 26,
    LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER: 27,

    //키노사다리 홀짝 , 좌우측, 3줄4줄
    LEISURE_ATTRIBUTE_KINOSADALI_ODDEVEN: 30,
    LEISURE_ATTRIBUTE_KINOSADALI_LEFT_RIGHT: 31,
    LEISURE_ATTRIBUTE_KINOSADALI_LINE_COUNT: 32,
    LEISURE_ATTRIBUTE_KINOSADALI_CROSS: 33,
    //카카오사다리
    LEISURE_ATTRIBUTE_KAKAO_SD_1_ODDEVEN: 140,
    LEISURE_ATTRIBUTE_KAKAO_SD_1_LEFT_RIGHT: 141,
    LEISURE_ATTRIBUTE_KAKAO_SD_1_LINE_COUNT: 142,

    LEISURE_ATTRIBUTE_KAKAO_SD_2_ODDEVEN: 143,
    LEISURE_ATTRIBUTE_KAKAO_SD_2_LEFT_RIGHT: 144,
    LEISURE_ATTRIBUTE_KAKAO_SD_2_LINE_COUNT: 145,

    LEISURE_ATTRIBUTE_KAKAO_SD_3_ODDEVEN: 146,
    LEISURE_ATTRIBUTE_KAKAO_SD_3_LEFT_RIGHT: 147,
    LEISURE_ATTRIBUTE_KAKAO_SD_3_LINE_COUNT: 148,

    //별다리
    LEISURE_ATTRIBUTE_STAR_SD_1_ODDEVEN: 149,
    LEISURE_ATTRIBUTE_STAR_SD_1_LEFT_RIGHT: 150,
    LEISURE_ATTRIBUTE_STAR_SD_1_LINE_COUNT: 151,

    LEISURE_ATTRIBUTE_STAR_SD_2_ODDEVEN: 152,
    LEISURE_ATTRIBUTE_STAR_SD_2_LEFT_RIGHT: 153,
    LEISURE_ATTRIBUTE_STAR_SD_2_LINE_COUNT: 154,

    LEISURE_ATTRIBUTE_STAR_SD_3_ODDEVEN: 155,
    LEISURE_ATTRIBUTE_STAR_SD_3_LEFT_RIGHT: 156,
    LEISURE_ATTRIBUTE_STAR_SD_3_LINE_COUNT: 157,

    LEISURE_ATTRIBUTE_STAR_SD_1_CROSS: 190,
    LEISURE_ATTRIBUTE_STAR_SD_2_CROSS: 191,
    LEISURE_ATTRIBUTE_STAR_SD_3_CROSS: 192,

    //네임드 달팽이 홀짝 , 오버언더, 우승번호
    LEISURE_ATTRIBUTE_NAMEDSNAIL_ODDEVEN: 50,
    LEISURE_ATTRIBUTE_NAMEDSNAIL_OVERUNDER: 51,
    LEISURE_ATTRIBUTE_NAMEDSNAIL_WINNUMBER: 52,

    //네임드 다리다리
    LEISURE_ATTRIBUTE_NAMEDDALIDALI_ODDEVEN: 35,
    LEISURE_ATTRIBUTE_NAMEDDALIDALI_LEFT_RIGHT: 36,
    LEISURE_ATTRIBUTE_NAMEDDALIDALI_LINE_COUNT: 37,
    //네임드 사다리
    LEISURE_ATTRIBUTE_NAMEDSADALI_ODDEVEN: 40,
    LEISURE_ATTRIBUTE_NAMEDSADALI_LEFT_RIGHT: 41,
    LEISURE_ATTRIBUTE_NAMEDSADALI_LINE_COUNT: 42,
    //네임드 speed사다리
    LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN: 45,
    LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT: 46,
    LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT: 47,

    //FX
    //FX1M
    LEISURE_ATTRIBUTE_FX_1M_ODDEVEN: 100,
    LEISURE_ATTRIBUTE_FX_1M_OVERUNDER: 101,
    LEISURE_ATTRIBUTE_FX_1M_JG_SELLBUY: 102,
    LEISURE_ATTRIBUTE_FX_1M_CG_SELLBUY: 103,
    //FX2M
    LEISURE_ATTRIBUTE_FX_2M_ODDEVEN: 104,
    LEISURE_ATTRIBUTE_FX_2M_OVERUNDER: 105,
    LEISURE_ATTRIBUTE_FX_2M_JG_SELLBUY: 106,
    LEISURE_ATTRIBUTE_FX_2M_CG_SELLBUY: 107,
    //FX3M
    LEISURE_ATTRIBUTE_FX_3M_ODDEVEN: 108,
    LEISURE_ATTRIBUTE_FX_3M_OVERUNDER: 109,
    LEISURE_ATTRIBUTE_FX_3M_JG_SELLBUY: 110,
    LEISURE_ATTRIBUTE_FX_3M_CG_SELLBUY: 111,
    //FX4M
    LEISURE_ATTRIBUTE_FX_4M_ODDEVEN: 112,
    LEISURE_ATTRIBUTE_FX_4M_OVERUNDER: 113,
    LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY: 114,
    LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY: 115,
    //FX5M
    LEISURE_ATTRIBUTE_FX_5M_ODDEVEN: 116,
    LEISURE_ATTRIBUTE_FX_5M_OVERUNDER: 117,
    LEISURE_ATTRIBUTE_FX_5M_JG_SELLBUY: 118,
    LEISURE_ATTRIBUTE_FX_5M_CG_SELLBUY: 119,

    LEISURE_ATTRIBUTE_BET365_DOGRACE_GOLDENHILLPARK_NORMAL: 60,
    LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL: 61,
    //Bet365 가상축구
    LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2: 62,
    LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER: 63,
    LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2: 64,
    LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER: 65,
    LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_1x2: 66,
    LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_OVERUNDER: 67,
    LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_1x2: 68,
    LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_OVERUNDER: 69,
    LEISURE_ATTRIBUTE_BET365_BASKETBALL_WARTER_1x2: 70,
    LEISURE_ATTRIBUTE_BET365_BASKETBALL_WARTER_OVERUNDER: 71,
    LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2: 72,
    LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER: 73,
    LEISURE_ATTRIBUTE_BET365_HORCERACE_NORMAL: 74,
    LEISURE_ATTRIBUTE_BET365_HORCERACE_2_NORMAL: 75, //브리타니아 웨이
    LEISURE_ATTRIBUTE_BET365_HORCERACE_3_NORMAL: 76, //페스티벌 다운즈
    LEISURE_ATTRIBUTE_BET365_HORCERACE_4_NORMAL: 77, //빅토리아 파크

    //바카라
    LEISURE_ATTRIBUTE_BAKARA_LOTUS_1_NORMAL: 90,
    //lotus홀짝
    LEISURE_ATTRIBUTE_LOTUS_CARD_ODDEVEN: 96,
    LEISURE_ATTRIBUTE_LOTUS_CARD_OVERUNDER: 92,
    LEISURE_ATTRIBUTE_LOTUS_CARD_MUNI: 93,
    LEISURE_ATTRIBUTE_LOTUS_CARD_COLOR: 94,
    //드래곤타이거
    LEISURE_ATTRIBUTE_LOTUS_DRAGONTIGER_NORMAL: 98,

    LEISURE_ATTRIBUTE_SUTDA_NORMAL: 120,
    LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER: 121,
    LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN: 122,
    LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR: 123,

    LEISURE_ATTRIBUTE_SKY_SPEED_BAKARA_NORMAL: 130,//스카이 스피드 바카라
    LEISURE_ATTRIBUTE_SKY_CARD_ODDEVEN: 131, //스카이 홀짝
    LEISURE_ATTRIBUTE_SKY_CARD_MUNI: 132,//스카이 홀짝에 히든카드 무니

    LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN: 133,//스카이 주사위 홀짝
    LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER: 134,//스카이 주사위 오버언더
    LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR: 135,//스카이 주사위 높은수자 색상

    //mgm바카라
    LEISURE_ATTRIBUTE_BAKARA_MGM_NORMAL: 95,

    //mgm/홀짝
    LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN: 97,

    //EOS 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_OVERUNDER_1M: 160,
    //EOS 파워볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODD_EVEN_1M: 161,
    //EOS 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_1M: 162,
    //EOS 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_ODD_EVEN_1M: 163,
    //EOS 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_BMS_1M: 164,
    //EOS 파워볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODDEVEN_OVERUNDER_CORSS_1M: 165,
    //EOS 일반볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_1M: 166,


    //EOS 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_OVERUNDER_3M: 170,
    //EOS 파워볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODD_EVEN_3M: 171,
    //EOS 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_3M: 172,
    //EOS 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_ODD_EVEN_3M: 173,
    //EOS 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_BMS_3M: 174,
    //EOS 파워볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODDEVEN_OVERUNDER_CORSS_3M: 175,
    //EOS 일반볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_3M: 176,


    //EOS 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_OVERUNDER_5M: 180,
    //EOS 파워볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODD_EVEN_5M: 181,
    //EOS 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_5M: 182,
    //EOS 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_ODD_EVEN_5M: 183,
    //EOS 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NOMALBALL_BMS_5M: 184,
    //EOS 파워볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_ODDEVEN_OVERUNDER_CORSS_5M: 185,
    //EOS 일반볼 홀짝+언더오버
    LEISURE_ATTRIBUTE_EOS_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_5M: 186,


    /*HS*/
    //하운슬로 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_HS_POWERBALL_OVERUNDER_3M: 210,
    //하운슬로 파워볼 홀,짝
    LEISURE_ATTRIBUTE_HS_POWERBALL_ODD_EVEN_3M: 211,
    //하운슬로 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_3M: 212,
    //하운슬로 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALL_ODD_EVEN_3M: 213,
    //하운슬로 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALL_BMS_3M: 214,

    //하운슬로 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_HS_POWERBALL_OVERUNDER_5M: 220,
    //하운슬로 파워볼 홀,짝
    LEISURE_ATTRIBUTE_HS_POWERBALL_ODD_EVEN_5M: 221,
    //하운슬로 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_5M: 222,
    //하운슬로 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALL_ODD_EVEN_5M: 223,
    //하운슬로 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_HS_POWERBALL_NOMALBALL_BMS_5M: 224,

    //스피드게임 황금돼지 사다리 홀짝 , 좌우측, 3줄4줄
    LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN: 225,
    LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT: 226,
    LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT: 227,
    //스피드게임 라이언무지
    LEISURE_ATTRIBUTE_SPEEDGAME_LM_NORMAL: 228,
    //스피드게임 스피릿 홀짝
    LEISURE_ATTRIBUTE_SPEEDGAME_SPLIT_ODDEVEN: 229,

    //JWCASINO
    LEISURE_ATTRIBUTE_JWCASINO_ANGELDEMONS_NORMAL: 230,//천사 악마
    LEISURE_ATTRIBUTE_JWCASINO_OSTRICHRUN_LEFT_OR_RIGHT: 231, //타조게임
    LEISURE_ATTRIBUTE_JWCASINO_SUPERMARIO_GOLD_OR_SILVER: 232, //슈퍼마리오
    LEISURE_ATTRIBUTE_JWCASINO_SUPERMARIO_BIG_OR_SMALL: 233, //슈퍼마리오

    //  //GameMoa
    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_1M_ODDEVEN: 240,
    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_1M_LINE_COUNT: 241,
    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_1M_LEFT_RIGHT: 242,

    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_5M_ODDEVEN: 245,
    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_5M_LINE_COUNT: 246,
    LEISURE_ATTRIBUTE_GAMEMOA_STAR_SADALI_5M_LEFT_RIGHT: 247,

    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_1M_ODDEVEN: 251,
    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_1M_LINE_COUNT: 252,
    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_1M_LEFT_RIGHT: 253,

    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_3M_ODDEVEN: 261,
    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_3M_LINE_COUNT: 262,
    LEISURE_ATTRIBUTE_GAMEMOA_BUBBLE_SADALI_3M_LEFT_RIGHT: 263,

    LEISURE_ATTRIBUTE_GAMEMOA_ACE_ODDEVEN: 271,
    LEISURE_ATTRIBUTE_GAMEMOA_ACE_OVERUNDER: 272,

    //네임드 뉴파워볼
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_POWERBALL_ODDEVEN_OVERUNDER_CROSS: 276,  //파워볼 조합
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS: 277,  //파워볼 조합
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS: 278,  //일반볼 조합
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS: 279,  //일반볼 조합

    //네임드 뉴파워볼 3분
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_POWERBALL_OVERUNDER: 280,  //파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_POWERBALL_ODDEVEN: 281,  //파워볼 홀짝
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_NORMALBALLTOTAL_OVERUNDER: 282,  //일반볼 오버언더
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_NORMALBALL_ODDEVEN: 283,  //일반볼 홀짝
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_3S_NORMALBALL_BMS: 284,  //대중소
    //네임드 뉴파워볼 5분
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER: 285,  //파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN: 286,  //파워볼 홀짝
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER: 287,  //일반볼 오버언더
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN: 288,  //일반볼 홀짝
    LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS: 289,  //대중소

    //네임드 뉴파워사다리 3분
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_3S_ODDEVEN: 290,
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_3S_LEFT_RIGHT: 291,
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_3S_LINE_COUNT: 292,
    //네임드 뉴파워사다리 5분
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_5S_ODDEVEN: 293,
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_5S_LEFT_RIGHT: 294,
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_5S_LINE_COUNT: 295,

    LEISURE_ATTRIBUTE_NEWPOWERSADALI_3S_CROSS: 296,
    LEISURE_ATTRIBUTE_NEWPOWERSADALI_5S_CROSS: 297,

    //동행스피드키노
    LEISURE_ATTRIBUTE_NAMED_DONGAHNG_SPEEDKINO_POWERBALL_OVERUNDER: 296,  //파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_NAMED_DONGAHNG_SPEEDKINO_POWERBALL_ODDEVEN: 297,  //파워볼 홀짝
    LEISURE_ATTRIBUTE_NAMED_DONGAHNG_SPEEDKINO_SUPERBALL_OVERUNDER: 298,  //슈퍼볼 오버언더
    LEISURE_ATTRIBUTE_NAMED_DONGAHNG_SPEEDKINO_SUPERBALL_ODDEVEN: 299,  //슈퍼볼 홀짝

    //넥스트게임
    LEISURE_ATTRIBUTE_NEXT_SADALI_CROSS: 299,
    LEISURE_ATTRIBUTE_NEXT_SADALI_ODDEVEN: 300,
    LEISURE_ATTRIBUTE_NEXT_SADALI_LEFT_RIGHT: 301,
    LEISURE_ATTRIBUTE_NEXT_SADALI_LINE_COUNT: 302,
    LEISURE_ATTRIBUTE_NEXT_BACCARA_NORMAL: 303,//넥스트 바카라
    LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL: 304,//넥스트 1분링


    //BOSCORE 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_BOSCORE_POWERBALL_OVERUNDER: 305,
    //BOSCORE 파워볼 홀,짝
    LEISURE_ATTRIBUTE_BOSCORE_POWERBALL_ODD_EVEN: 306,
    //BOSCORE 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_BOSCORE_POWERBALL_NOMALBALLTOTOAL_OVERUNDER: 307,
    //BOSCORE 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_BOSCORE_POWERBALL_NOMALBALL_ODD_EVEN: 308,
    //BOSCORE 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_BOSCORE_POWERBALL_NOMALBALL_BMS: 309,

    //슈어맨 1분 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_OVERUNDER_1M: 310,
    //슈어맨 1분 파워볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODD_EVEN_1M: 311,
    //슈어맨 1분 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_1M: 312,
    //슈어맨 1분 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_ODD_EVEN_1M: 313,
    //슈어맨 1분 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_BMS_1M: 314,

    //슈어맨 2분 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_OVERUNDER_2M: 315,
    //슈어맨 2분 파워볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODD_EVEN_2M: 316,
    //슈어맨 2분 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_2M: 317,
    //슈어맨 2분 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_ODD_EVEN_2M: 318,
    //슈어맨 2분 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_BMS_2M: 319,

    //슈어맨 3분 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_OVERUNDER_3M: 320,
    //슈어맨 3분 파워볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODD_EVEN_3M: 321,
    //슈어맨 3분 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALLTOTOAL_OVERUNDER_3M: 322,
    //슈어맨 3분 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_ODD_EVEN_3M: 323,
    //슈어맨 3분 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NOMALBALL_BMS_3M: 324,


    //슈어맨 1분 사다리
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN: 330,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT: 331,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT: 332,
    //슈어맨 2분 사다리
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_2M_ODDEVEN: 333,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_2M_LINE_COUNT: 334,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_2M_LEFT_RIGHT: 335,
    //슈어맨 3분 사다리
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_3M_ODDEVEN: 336,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_3M_LINE_COUNT: 337,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_3M_LEFT_RIGHT: 338,
    //슈어맨 사다리 조합
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS: 327,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_2M_CROSS: 328,
    LEISURE_ATTRIBUTE_SUREMAN_SADALI_3M_CROSS: 329,

    LEISURE_ATTRIBUTE_PAMGAME_MARIO_NORMAL: 340,
    LEISURE_ATTRIBUTE_PAMGAME_SLOT_NORMAL: 341,
    LEISURE_ATTRIBUTE_PAMGAME_DRAGONTIGER_NORMAL: 342,

    //슈어맨 파워볼 크로스 홀짝+오버언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODDEVEN_OVERUNDER_CORSS_1M: 343,
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODDEVEN_OVERUNDER_CORSS_2M: 344,
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_ODDEVEN_OVERUNDER_CORSS_3M: 345,
    //슈어맨 일반볼 크로스 홀짝+오버언더
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_1M: 346,
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_2M: 347,
    LEISURE_ATTRIBUTE_SUREMAN_POWERBALL_NORMALBALL_ODDEVEN_OVERUNDER_CORSS_3M: 348,

    //NEXT 파워볼 오버 ,언더
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_OVERUNDER: 350,
    //NEXT 파워볼 홀,짝
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_ODD_EVEN: 351,
    //NEXT 파워볼 일반볼합계 오버,언더
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_NOMALBALLTOTOAL_OVERUNDER: 352,
    //NEXT 파워볼 일반볼 홀,짝
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_NOMALBALL_ODD_EVEN: 353,
    //NEXT 파워볼 일반볼 대,중,소 Big Middle Small
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_NOMALBALL_BMS: 354,
    //NEXT 파워볼 조합
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_ODD_EVEN_OVERUNDER_CROSS: 355,
    LEISURE_ATTRIBUTE_NEXT_POWERBALL_NORMALBALL_ODD_EVEN_OVERUNDER_CROSS: 356,


    /*Tgame365*/

    ROULETTE: "roulette", //룰렛
    HILO: "hilo",//하이로
    HILO_5S: "hilo_5s",//하이로5초
    GRAPH: "graph",//그래프
    BACCARAT: "baccarat",//바카라
    HORSER: "horse",//가상경마
    KENO: "keno",//월드키노
    FW: "fw",//토큰힐
    SLOT: "slot",//토큰슬롯
    VSPORTS: "vsports",//가상스포츠
    FISH: "fish",//토큰 피쉬게임
    SNAIL3: "snail3",//토큰 달팽이레이싱
    SNAIL4: "snail4",//토토큰 달팽이레이싱
    EVOLUTION: "evolution",//라이브카지노(에볼루션)
    AGCASINO: "agcasino",//라이브카지노(아시아게이밍)
    SEXYGAMING: "sexygaming",//라이브카지노(섹시게이밍)


    //게임결과 정상입력 1 ,  waiting:cancel:specialcase일경우 2
    LEISURE_RESULT_CANCEL_TEXT: 'cancel',
    LEISURE_RESULT_SPECIALCASE_TEXT: 'specialcase',

}
import http from "./axios/http";

/*로그인*/
export function login(user) {
    return http.post('/user/login?t=' + new Date().getTime(), user)
}

/*로그아웃*/
export function logout() {
    return http.get('/user/logout?t=' + new Date().getTime())
}

/*회원가입시 아이디중복 체크*/
export function checkUserName(username) {
    return http.post('/user/check_username?t=' + new Date().getTime(), {'username': username})
}

/*회원가입시 닉네임중복 체크*/
export function checkNickName(nickname) {
    return http.post('/user/check_nickname?t=' + new Date().getTime(), {'nickname': nickname})
}

/*폰인증코드 신청*/
export function getVerificationCode(phone) {
    return http.post('/user/get_verification_code?t=' + new Date().getTime(), {'phone': phone})
}
/*폰인증코드 신청*/
export function getVerificationImgCode() {
    return http.get('/user/verifyimgcode?t=' + new Date().getTime())
}

export function checkAgentCode(user) {
    return http.post('/user/check_agentcode?t=' + new Date().getTime(), user)
}

/*회원가입*/
export function register(user) {
    return http.post('/user/register?t=' + new Date().getTime(), user)
}

/*회원정보*/
export function getUserInfo() {
    return http.get('/user/user_info?t=' + new Date().getTime())
}

/*회원추천인포인트내역*/
export function referrerPointLog(pageNum, pageSize) {
    return http.get('/user/referrer_point_log?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime())
}

/*회원추천인*/
export function referrerUsers(pageNum, pageSize) {
    return http.get('/user/referrer_users?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime())
}


/*비밀번호변경*/
export function changePasswd(user) {
    return http.post('/user/change_passwd?t=' + new Date().getTime(), user)
}

/*회원 포인트 전환*/
export function changePoint() {
    return http.get('/user/change_point?t=' + new Date().getTime())
}


/*회원위치*/
export function setPosition(position) {
    return http.get('/user/set_position?position=' + position)
}

/*출첵정보*/
export function getCulcheck() {
    return http.get('/user/get_culcheck?t=' + new Date().getTime())
}

/*1:1문의 리스트*/
export function getQuestionList(pageNum, pageSize, orderBy) {
    return http.post('/customercenter/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime())
}

export function saveQuestion(customerBody) {
    return http.post('/customercenter/save', customerBody)
}

export function delQuestion(id) {
    return http.get('/customercenter/update?id=' + id)
}



/*게시판*/
export function saveBoard(board) {
    return http.post('/board/save', board)
}

export function saveBoardComment(comment) {
    return http.post('/board/save_comment', comment)
}

export function getBoardList(pageNum, pageSize, orderBy) {
    return http.post('/board/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime(), {})
}
export function getFreeBoard(pageNum, pageSize) {
    return http.get('/board/freeboard?pageNum=' + pageNum + '&pageSize=' + pageSize +'&t=' + new Date().getTime(), {})
}

/*공지사항*/
export function getNoticeList(pageSize) {
    return http.get('/board/notice?pageSize=' + pageSize + '&t=' + new Date().getTime())
}


/*베팅규정*/
export function getBetRuleList() {
    return http.get('/board/betrule?t=' + new Date().getTime())
}

export function getBoardDetail(id) {
    return http.get('/board/detail?id=' + id + '&t=' + new Date().getTime())
}

export function getEvent(pageNum, pageSize) {
    return http.post('/board/event?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime(), {})
}

export function getEventDetail(id) {
    return http.get('/board/event_detail?id=' + id + '&t=' + new Date().getTime())
}

/*입출금*/
export function getApplyCashList(search) {
    return http.post('/applycash/list?t=' + new Date().getTime(), search)
}

export function rechargeCash(applyCash) {
    return http.post('/applycash/recharge?t=' + new Date().getTime(), applyCash)
}

export function exchangeCash(applyCash) {
    return http.post('/applycash/exchange?t=' + new Date().getTime(), applyCash)
}
export function getMyRolling() {
    return http.get('/applycash/get_my_rolling?t=' + new Date().getTime())
}

export function getinfotext() {
    return http.get('/applycash/get_infotext?t=' + new Date().getTime())
}
export function deleteApply(id) {
    return http.get('/applycash/delete_by_id?t=' + new Date().getTime()+'&id='+id)
}



export function getM7OutSideLoginUrl() {
    return http.get('/applycash/get_outsidelogin_url?t=' + new Date().getTime())
}

export function getTodayRechargeStatus() {
    return http.get('/applycash/get_today_recharge_status?t=' + new Date().getTime())
}



/***쪽지***/
export function getMessageList(pageNum, pageSize) {
    return http.get('/message/message_list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime())
}

export function checkedMessageById(id) {
    return http.get('/message/chekced_by_id?id=' + id)
}

export function deleteMessageById(id) {
    return http.get('/message/delete_by_id?id=' + id)
}

export function checkedMessageAll() {
    return http.get('/message/checked_all?t=' + new Date().getTime())
}

export function deleteMessageAll() {
    return http.get('/message/delete_all?t=' + new Date().getTime())
}

export function getUnCheckedMessageCount() {
    return http.get('/message/message_count?t=' + new Date().getTime())
}

export function getPopupList(platform) {
    return http.get('/user/popup_list?platform=' + platform + '&t=' + new Date().getTime())
}

//get_line_message
export function getLineMessage() {
    return http.get('/board/get_line_message?t=' + new Date().getTime())
}

//site_status
export function getSiteStatus() {
    return http.get('/user/site_status?t=' + new Date().getTime())
}

//t365hash
export function getTgame365Hash() {
    return http.get('/tgame365/hash?t=' + new Date().getTime())
}

/*회원 포인트 페이백*/
export function payback() {
    //return http.get('/user/payback?t=' + new Date().getTime())
    return http.get('/user/paybackcd?t=' + new Date().getTime())
}
/**/
/*회원 포인트 페이백*/
export function daypayback() {
    //return http.get('/user/payback?t=' + new Date().getTime())
    return http.get('/user/daypayback?t=' + new Date().getTime())
}
/*회원 포인트 페이백*/
export function weekpayback() {
    //return http.get('/user/payback?t=' + new Date().getTime())
    return http.get('/user/weekpayback?t=' + new Date().getTime())
}
export function coffetimevent() {
    return http.get('/user/coffetimevent?t=' + new Date().getTime())
}
export function owlevent() {
    return http.get('/user/owlevent?t=' + new Date().getTime())
}


export function tgamebetcount() {
    return http.get('/user/get_day_tgame_betcount?t=' + new Date().getTime())
}
export function saveNotMemberQuestion(customerBody) {
    return http.post('/user/nologinask', customerBody)
}
export function getnotmemberanswer() {
    return http.get('/user/get_notlogin_ask_answer?t=' + new Date().getTime())
}


